import React from 'react'
import {_AccordionButton} from "./_AccordionButton";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import {useSelector} from "react-redux";
import {Loading} from "../../../ui/loading/Loading";
import {zynity_api} from "../../../../config/external-routes";
import {_Comments} from "./_Comments";
import {_SubDatum} from "./_SubDatum";
import axios from "axios";

export const DatumAccordionItem = ({datum, total_coach_notes, handleEditItem, handleDeleteItem, handleAddComment, handleEditComment, clickRemoveComment, handleChangeListOrder, handleOpenReminder, isSubItem, clickNewItem, readOnly, subdatumModalOpenKey, handleArchiveItem, handleActiveItem, handleMarkPrivateItem, handleMarKNotificationsAsRead, clickMoveItem, selectedMember, handleSelectItem}:any) => {
    const team_obj = useSelector((state: any) => state.organization_team);
    const authenticated_user = useSelector((state: any) => state.auth);
    const a = moment();
    const b = moment(datum.dateCreated);
    const days = a.diff(b, 'days')

    if(team_obj.isLoading === true) {
        return <Loading />;
    }

    const getAuthorKnowAs = (people_id: number) => {
        let author = team_obj?.data?.members?.find((member: { id: number; }) => (member.id === people_id))
        if(author === undefined) {
            return 'Not Found';
        }

        return author.KnownAs;
    }

    const getAuthorInitials = (people_id: number) => {
        let author = team_obj?.data?.members?.find((member: { id: number; }) => (member.id === people_id))
        if(author === undefined) {
            return '--';
        }

        return `${author.FirstName.substring(0, 1)}${author.LastName.substring(0, 1)}`;
    }

    let color = '#FFFADE'
    if(datum.isPrivate == 'Private') {
        color = '#c5c5c5'
    }

    let is_author = false;
    if(selectedMember != undefined && datum.type1 == 'Message' && datum.idAuthor == authenticated_user.id) {
        is_author = true;
    }

    const downloadFile = async (filePath: string, customName: string) => {
        const API_URL = `${zynity_api.global_secure_download_files}`;

        try {
            const response = await axios.get(API_URL, {
                params: {
                    path: filePath,
                    force_download: true,
                    custom_name: customName,
                },
                headers: {
                    Authorization: `Bearer ${authenticated_user.access_token}`,
                },
                responseType: "blob", // Handle binary file data
            });

            // Determine file type from response headers
            const contentType = response.headers["content-type"];
            const blob = new Blob([response.data], { type: contentType });

            // Automatically determine file extension if not provided
            const fileExtension = contentType.split("/")[1] || "file";
            const finalFileName = customName.includes(".") ? customName : `${customName}.${fileExtension}`;

            // Create a Blob URL and trigger download
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", finalFileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    return (
        <Accordion.Item eventKey={datum.id.toString()} style={{backgroundColor: color, color: 'black', borderColor: '#9b9b9b'}}>
            <_AccordionButton eventKey={datum.id.toString()} coach_note={datum} total_coach_notes={total_coach_notes}
                              handleEditItem={handleEditItem} handleDeleteItem={handleDeleteItem}
                              handleAddComment={handleAddComment} handleChangeListOrder={handleChangeListOrder}
                              handleArchiveItem={handleArchiveItem}
                              handleMarkPrivateItem={handleMarkPrivateItem}
                              handleSelectItem={handleSelectItem}
                              handleMarKNotificationsAsRead={handleMarKNotificationsAsRead}
                              handleActiveItem={handleActiveItem}
                              clickNewItem={clickNewItem}
                              clickMoveItem={clickMoveItem}
                              readOnly={readOnly}
                              is_author={is_author}
                              isSubItem={isSubItem}
                              handleOpenReminder={handleOpenReminder}>{datum.title}</_AccordionButton>
            <Accordion.Body style={{backgroundColor: 'white'}}>
                <div className="row">
                    <div className="col-3 col-md-2 col-lg-1 p-0 border-right">Story</div>
                    <div className="col-9 col-md-10 col-lg-11" dangerouslySetInnerHTML={{__html: datum.description}}/>

                    <div className="col-12 mt-1 mb-1 p-1" style={{backgroundColor: '#F1F1F1'}}>
                        {
                            datum.type1 == 'Message' &&
                            <div className="rounded-circle" style={{backgroundColor: 'teal', padding: '5px', color: 'white', display: 'inline-block', textAlign: 'center', fontSize: '10px', fontFamily: 'sans-serif'}}>
                                {getAuthorInitials(datum.idAuthor)}
                            </div>
                        }
                        <span className='mx-2'><span className='text-muted'>Created: </span>{moment(datum.dateCreated).format('DD MMM YYYY')}</span>
                        <span className='mx-3'><span className='text-muted'>Last Edit: </span>{moment(datum.lastModified).format('DD MMM YYYY')}</span>
                        <span className='mx-3'><span className='text-muted'>Open: </span>+{days} days</span>
                    </div>

                    {
                        datum?.links?.length > 0 &&
                        <>
                            <div className="col-3 col-md-2 col-lg-1 p-0 border-right mt-1">Links</div>
                            <div className="col-9 col-md-10 col-lg-11 mt-1">
                                {
                                    datum.links.map((link: any) => (
                                        <div><a className='color-teal' href={link.document_teams.document.displayName} target='_blank'>{link.document_teams.document.displayName}</a></div>
                                    ))
                                }
                            </div>

                            <hr className='mt-2' style={{border: 0, borderTop: '1px solid #d1d1d1'}}/>
                        </>
                    }

                    {
                        datum?.uploads?.length > 0 &&
                        <>
                            <div className="col-3 col-md-2 col-lg-1 p-0 border-right mt-1">Uploads</div>
                            <div className="col-9 col-md-10 col-lg-11 mt-1">
                                {
                                    datum.uploads.map((uploads: {
                                        document_teams: any;
                                        displayName: any;
                                        fileName: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; }) => (
                                        <div>
                                            <button
                                                onClick={() => downloadFile(uploads.document_teams.document.path, uploads.document_teams.document.displayName)}
                                                className={'color-teal'}
                                                style={{
                                                    background: "none",
                                                    border: "none",
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                    padding: 0,
                                                    fontSize: "inherit",
                                                    fontFamily: "inherit",
                                                }}
                                            >{uploads.document_teams.document.displayName}</button>
                                        </div>
                                    ))
                                }
                            </div>

                            <hr className='mt-2' style={{border: 0, borderTop: '1px solid #d1d1d1'}}/>
                        </>
                    }

                    <_Comments datum={datum} handleAddComment={handleAddComment} handleEditComment={handleEditComment} clickRemoveComment={clickRemoveComment} />

                    {
                        isSubItem === false && datum.type1 != 'Message' &&
                        <_SubDatum
                            datum={datum}
                            clickEditItem={handleEditItem}
                            clickAddComment={handleAddComment}
                            handleChangeListOrder={handleChangeListOrder}
                            handleOpenReminder={handleOpenReminder}
                            handleMarkPrivateItem={handleMarkPrivateItem}
                            clickEditComment={handleEditComment}
                            clickRemoveComment={clickRemoveComment}
                            clickDeleteItem={handleDeleteItem}
                            clickNewItem={clickNewItem}
                            handleArchiveItem={handleArchiveItem}
                            handleActiveItem={handleActiveItem}
                            handleMarKNotificationsAsRead={handleMarKNotificationsAsRead}
                            readOnly={readOnly}
                            subdatumModalOpenKey={subdatumModalOpenKey}
                            handleSelectItem={handleSelectItem}
                        />
                    }

                </div>
            </Accordion.Body>
        </Accordion.Item>
    )
}
