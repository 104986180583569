import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Loading} from "../../../../../ui/loading/Loading";
import Nav from "react-bootstrap/Nav";
import {HeadlineModal} from "../../../../../ui/tools/HeadlineModal";
import {ReminderModal} from "../../../../../ui/tools/ReminderModal";
import './MemberPanel.scss'
import Accordion from "react-bootstrap/Accordion";
import {DatumAccordionItem} from "../../../components/DatumAccordionItem";
import {zynity_api} from "../../../../../../config/external-routes";
import Swal from "sweetalert2";
import axios from "axios";
import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';
import {getCoachNotes, setCoachNoteOpen} from "../../../../../../store/coaches/teams/coach_notes";
import {
    collabSetRemember,
    getCnAData, ICollabRemember,
    setDatumIdOpen,
    setDatumIdOpenData, updateCnAData
} from "../../../../../../store/user_organizations/teams/tools/connect_and_align";
import {findDatumByDatumId, findDatumByDatumIdInAllTools} from "../../../../../../helpers/tools";
import {changePriority} from "../../../../../../helpers/changeListOrder";
import {setRoute} from "../../../../../../store/routes";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, Modal} from "react-bootstrap";

class DraggableModalDialog extends React.Component {
    render() {
        return <Draggable handle=".draggableHandler"><ModalDialog {...this.props} />
        </Draggable>
    }
}

export const MemberPanel = ({selectedMember}: any) => {
    const navigateTo = useNavigate()
    const dispatch = useDispatch();
    const app_router = useSelector((state: any) => state.router)
    const cna = useSelector((state: any) => state.tool_cna)
    let cna_data = cna.data;
    let cna_archived_data = cna.archived_data;
    let isLoadingArchivedData = cna.isLoadingArchivedData;
    const authenticated_user = useSelector((state: any) => state.auth);
    const team_obj = useSelector((state: any) => state.organization_team);
    const tool_cna_remember = useSelector((state: any) => state.tool_cna_remember);
    const [showReminderModal, setShowReminderModal] = useState(false);
    const [showMoveItemModal, setShowMoveItemModal] = useState(false);
    const [moveItemSelectedItemId, setMoveItemSelectedItemId] = useState(0);
    const [moveItemNewType, setMoveItemNewType] = useState('');
    const [isNewReminder, setIsNewReminder] = useState(true);
    const [reminder, setReminder] = useState('');
    const [showCreateItemModal, setShowCreateItemModal] = useState(false);
    const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
    let team_services = team_obj.data.services[0];
    const [headlineModalSettings, setHeadlineModalSettings] = useState({
        title: 'New ToMI',
        is_comment: false,
        new_record: true,
        labels: {
            title: 'ToMI',
            description: 'Story'
        }
    });
    const [headlineData, setHeadlineData] = useState({
        content_id: '',
        title: '',
        description: '',
        links: [],
        uploads: [],
    });

    let 
    
    datum_types = [
        {
            name: 'Deliverables',
            singular: 'Deliverable'
        }
    ];

    if(team_services.SKU == 'Z01B' || team_services.SKU == 'Z01C') {
        datum_types.push({
                name: 'ToMIs',
                singular: 'ToMI'
            },
            {
                name: 'Activities',
                singular: 'Activity'
            })
    }

    datum_types.push({
        name: 'Messages',
        singular: 'Message'
    })

    const parseToolsNamesToTabNames = (tool:string) => {
        if(tool == 'deliverables') {
            return 'Deliverables'
        } else if(tool == 'tomis') {
            return 'ToMIs'
        } else if(tool == 'activities') {
            return 'Activities'
        } else if(tool == 'messages') {
            return 'Messages'
        }
    }

    const getDatumModalOpenKey = () => {

        let datumOpenId = cna.datum_id_open.toString();
        console.log("datumOpenId", datumOpenId)
        if(datumOpenId == '0') {
            if(cna.isLoading === false && cna.isLoadingFullData === false) {
                let remember_data = tool_cna_remember?.remember
                if(remember_data != null && remember_data.length > 0) {
                    const organization_id = team_obj.data.idOrganization;
                    const team_id = team_obj.data.id;

                    let organizationData = remember_data.find((org: { organization_id: number; team_id:number; view:'team'|'member'}) => (
                        org.organization_id === organization_id && org.team_id === team_id && org.view === 'member'
                    ));

                    if(organizationData) {
                        let user_data = organizationData.users.find((user: { id: any; }) => user.id == selectedMember);
                        if(user_data != undefined && user_data?.tabs != null && user_data.tabs.length > 0) {

                            let selected_tab = user_data.tabs.find((tab: { open: boolean; }) => tab.open == true)
                            if(selected_tab != undefined && selected_tab?.name != undefined && selected_tab?.name.trim() != '') {
                                if(selected_tab?.selected_item_id != undefined) {
                                    //verifiy if id is sub
                                    let selected_datum = findDatumByDatumIdInAllTools(cna_data, parseInt(selected_tab.selected_item_id))
                                    if(selected_datum?.datum_found?.idParent != undefined) {
                                        datumOpenId = selected_datum.datum_found.idParent.toString();
                                    } else {
                                        datumOpenId = selected_tab.selected_item_id.toString();
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            if(cna?.datum_id_open_data?.datum_found?.idParent != null) {
                return [cna.datum_id_open_data.datum_found.idParent.toString()]
            }
        }

        return [datumOpenId]
    }
    const getSubDatumModalOpenKey = () => {
        if(cna?.datum_id_open_data?.datum_found?.idParent != null) {
            return [cna.datum_id_open.toString()]
        } else {
            if(cna.isLoading === false && cna.isLoadingFullData === false) {
                let remember_data = tool_cna_remember?.remember
                if(remember_data != null && remember_data.length > 0) {
                    const organization_id = team_obj.data.idOrganization;
                    const team_id = team_obj.data.id;

                    let organizationData = remember_data.find((org: { organization_id: number; team_id:number; view:'team'|'member'}) => (
                        org.organization_id === organization_id && org.team_id === team_id && org.view === 'member'
                    ));

                    if(organizationData) {
                        let user_data = organizationData.users.find((user: { id: any; }) => user.id == selectedMember);
                        if(user_data != undefined && user_data?.tabs != null && user_data.tabs.length > 0) {

                            let selected_tab = user_data.tabs.find((tab: { open: boolean; }) => tab.open == true)
                            if(selected_tab != undefined && selected_tab?.name != undefined && selected_tab?.name.trim() != '') {
                                if(selected_tab?.selected_item_id != undefined) {
                                    //verifiy if id is sub
                                    let selected_datum = findDatumByDatumIdInAllTools(cna_data, parseInt(selected_tab.selected_item_id))
                                    if(selected_datum?.datum_found?.idParent != undefined) {
                                        return [selected_tab.selected_item_id.toString()]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return ["0"]
    }
    const getSelectedTool = () => {
        if(cna?.datum_id_open_data?.selected_tool !== undefined) {
            return parseToolsNamesToTabNames(cna.datum_id_open_data.selected_tool)
        }
        return 'ToMIs'
    }
    const [selectedDatumType, setSelectedDatumType] = useState(getSelectedTool());
    const [headlineOpenId, setHeadlineOpenId] = useState(null);
    const [headlineParentOpenId, setHeadlineParentOpenId] = useState(null);
    const [headlineCommentOpenId, setHeadlineCommentOpenId] = useState(null);
    const [datumModalOpenKey, setDatumModalOpenKey] = useState(getDatumModalOpenKey());
    const [subdatumModalOpenKey, setSubDatumModalOpenKey] = useState(getSubDatumModalOpenKey());
    const [selectedEmailMembers, setSelectedEmailMembers] = useState([]);

    useEffect(() => {
        let datumOpenId = cna.datum_id_open.toString();

        if(cna.isLoading === false && cna.isLoadingFullData === false && datumOpenId == '0') {
            let remember_data = tool_cna_remember?.remember
            if(remember_data != null && remember_data.length > 0) {
                const organization_id = team_obj.data.idOrganization;
                const team_id = team_obj.data.id;

                let organizationData = remember_data.find((org: { organization_id: number; team_id:number; view:'team'|'member'}) => (
                    org.organization_id === organization_id && org.team_id === team_id && org.view === 'member'
                ));

                if(organizationData) {
                    let user_data = organizationData.users.find((user: { id: any; }) => user.id == selectedMember);
                    if(user_data != undefined && user_data?.tabs != null && user_data.tabs.length > 0) {

                        let selected_tab = user_data.tabs.find((tab: { open: boolean; }) => tab.open == true)
                        if(selected_tab != undefined && selected_tab?.name != undefined && selected_tab?.name.trim() != '') {

                            console.log("selected_tab MEMBER PANEL", selected_tab)
                            if(selected_tab.name == 'deliverables') {
                                setSelectedDatumType('Deliverables')
                            } else if(selected_tab.name == 'tomis') {
                                setSelectedDatumType('ToMIs')
                            } else if(selected_tab.name == 'activities') {
                                setSelectedDatumType('Activities')
                            } else if(selected_tab.name == 'messages') {
                                setSelectedDatumType('Messages')
                            }

                            if(selected_tab?.selected_item_id != undefined) {
                                setDatumModalOpenKey(selected_tab.selected_item_id.toString())
                            }
                        }
                    }
                }
            }
        }
    }, [cna.isLoading, cna.isLoadingFullData])

    if(team_obj.isLoading || cna.isLoadingFullData) {
        return <Loading />;
    }

    let selected_member_is_owner = false;
    if(authenticated_user.id == selectedMember) {
        selected_member_is_owner = true;
    }

    let member = team_obj?.data?.members?.find((member: { id: number; }) => (member.id == selectedMember))
    let cna_member_data = cna_data.find((datum: { user_id: number; }) => (datum.user_id == selectedMember))

    let cna_member_archived_data: any = null;

    if(isLoadingArchivedData == false) {
        cna_member_archived_data = cna_archived_data.find((datum: { user_id: number; }) => (datum.user_id == selectedMember))
    }

    const clickNewItem = (type_name:string, parent_datum_id:number|null = null) => {
        if(type_name == 'Deliverable') {
            type_name = 'Deliverables';
        } else if(type_name == 'Headline') {
            type_name = 'ToMIs';
        } else if(type_name == 'Activity') {
            type_name = 'Activities';
        }

        setSelectedDatumType(type_name)

        let prefix_title = 'New'
        if(parent_datum_id != null && parent_datum_id > 0) {
            generateSpecificCnARoute(parent_datum_id)
            setHeadlineParentOpenId(parent_datum_id);
            prefix_title = 'New Sub'
        }

        if(type_name == 'Deliverables') {
            setHeadlineModalSettings({
                ...headlineModalSettings,
                title: `${prefix_title} Deliverable`,
                new_record: true,
                is_comment: false,
                labels: {
                    title: 'Deliverable',
                    description: 'Story'
                }
            });
        } else if(type_name == 'ToMIs') {
            setHeadlineModalSettings({
                ...headlineModalSettings,
                title: `${prefix_title} ToMI™`,
                new_record: true,
                is_comment: false,
                labels: {
                    title: 'ToMI™',
                    description: 'Story'
                }
            });
        
        } else if (type_name == 'Activities') {
            setHeadlineModalSettings({
                ...headlineModalSettings,
                title: `${prefix_title} Activity`,
                new_record: true,
                is_comment: false,
                labels: {
                    title: 'Activity',
                    description: 'Story'
                }
            });
        } else if (type_name == 'Messages') {
            setHeadlineModalSettings({
                ...headlineModalSettings,
                title: `${prefix_title} Message`,
                new_record: true,
                is_comment: false,
                labels: {
                    title: 'Message',
                    description: 'Message'
                }
            });
        }

        setHeadlineData({
            content_id: '',
            title: '',
            description: '',
            links: [],
            uploads: [],
        });
        setShowCreateItemModal(true);
        setSelectedEmailMembers([]);
    }

    const handleCloseCreateCoachNoteModal = () => {
        setHeadlineOpenId(null)
        setHeadlineCommentOpenId(null)
        setShowCreateItemModal(false)
    };

    const headlineSubmit = async () => {
        const organization_id = team_obj.data.idOrganization;
        const team_id = team_obj.data.id;
        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        url += `/tools/CnA`;

        if(headlineModalSettings.is_comment === false) {
            let type = 'Headline';
            if(selectedDatumType == 'Deliverables') {
                type = 'Deliverable'
            }  else if(selectedDatumType == 'ToMIs') {
                type = 'Headline'
            } else if (selectedDatumType == 'Activities') {
                type = 'Activity'
            } else if (selectedDatumType == 'Messages') {
                type = 'Message'
            }

            if(headlineParentOpenId != null && headlineParentOpenId > 0) {
                type = 'Subheadline'
            }

            let formData:any = {...headlineData, type: type};

            formData = {...formData, email_members: selectedEmailMembers}

            if (selectedDatumType == 'Messages') {
                formData = {...formData, assigned_to: selectedMember}
            }

            if(formData.title.trim() == '') {
                Swal.fire({
                    icon: 'warning',
                    text: 'Please add a note name'
                })
                return false;
            }

            setDisableSubmitBtn(true)
            if(headlineModalSettings.new_record === true) {
                if(type == 'Subheadline') {
                    url += '/' + headlineParentOpenId;
                }
                await axios.post(`${url}`, formData)
                    .then((response) => {
                        dispatch( getCnAData({organization_id, team_id}) );
                        setShowCreateItemModal(false)
                        setDisableSubmitBtn(false)
                    }).catch((error) => {
                        setDisableSubmitBtn(false)
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `We have problems creating your ${type}, please try again!`,
                        })
                        throw error;
                    })
            } else {
                url = `${url}/${headlineOpenId}`
                await axios.put(`${url}`, formData)
                    .then((response) => {
                        dispatch( getCnAData({organization_id, team_id}) );
                        setShowCreateItemModal(false)
                        setDisableSubmitBtn(false)
                    }).catch((error) => {
                        setDisableSubmitBtn(false)
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `We have problems updating your ${type}, please try again!`,
                        })
                        throw error;
                    })
            }
        } else {
            let formData:any = {...headlineData};

            formData = {...formData, email_members: selectedEmailMembers}

            if(formData.description.trim() == '') {
                Swal.fire({
                    icon: 'warning',
                    text: 'Please add a description'
                })
            }
            url = `${url}/${headlineOpenId}/comments`
            if(headlineCommentOpenId !== null && headlineCommentOpenId > 0) {
                url = `${url}/${headlineCommentOpenId}`
            }

            setDisableSubmitBtn(true)
            if(headlineModalSettings.new_record) {
                await axios.post(`${url}`, formData)
                    .then((response) => {
                        dispatch( getCnAData({organization_id, team_id}) );
                        setShowCreateItemModal(false)
                        setDisableSubmitBtn(false)
                    }).catch((error) => {
                        setDisableSubmitBtn(false)
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `We have problems creating your comment, please try again!`,
                        })
                        throw error;
                    })
            } else {
                await axios.put(`${url}`, formData)
                    .then((response) => {
                        dispatch( getCnAData({organization_id, team_id}) );
                        setShowCreateItemModal(false)
                        setDisableSubmitBtn(false)
                    }).catch((error) => {
                        setDisableSubmitBtn(false)
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `We have problems updating your comment, please try again!`,
                        })
                        throw error;
                    })
            }
        }
    }

    const handleCloseReminderModal = () => {
        setHeadlineOpenId(null)
        setShowReminderModal(false)
    };

    const clickEditItem = (datum_id:any) => {
        setHeadlineOpenId(datum_id)
        if(selectedDatumType == 'Deliverables') {
            setHeadlineModalSettings({
                ...headlineModalSettings,
                title: 'Edit Deliverable',
                new_record: false,
                is_comment: false,
                labels: {
                    title: 'Deliverable',
                    description: 'Story'
                }
            });
        } else if(selectedDatumType == 'ToMIs') {
            setHeadlineModalSettings({
                ...headlineModalSettings,
                title: 'Edit ToMI™',
                new_record: false,
                is_comment: false,
                labels: {
                    title: 'ToMI™',
                    description: 'Story'
                }
            });
        } else if (selectedDatumType == 'Activities') {
            setHeadlineModalSettings({
                ...headlineModalSettings,
                title: 'Edit Activity',
                new_record: false,
                is_comment: false,
                labels: {
                    title: 'Activity',
                    description: 'Story'
                }
            });
        } else if (selectedDatumType == 'Messages') {
            setHeadlineModalSettings({
                ...headlineModalSettings,
                title: 'Edit Message',
                new_record: false,
                is_comment: false,
                labels: {
                    title: 'Message',
                    description: 'Story'
                }
            });
        }

        let datum = findDatumByDatumId(cna_member_data[selectedDatumType.toLowerCase()].data, datum_id)
        let links:any = [];
        let uploads:any = [];
        if(datum?.links != undefined && datum.links.length > 0) {
            links = datum.links.map((upload: { id: number, fileName: string, displayName: string; }) => {
                return {
                    id: upload.document_teams.id,
                    link: upload.document_teams.document.displayName,
                };
            });
        }

        if(datum?.uploads != undefined && datum.uploads.length > 0) {
            uploads = datum.uploads.map((upload: { id: number, fileName: string, displayName: string; }) => {
                return {
                    id: upload.document_teams.id,
                    file_name: upload.document_teams.document.path,
                    display_name: upload.document_teams.document.displayName,
                };
            });
        }

        setHeadlineData({
            content_id: datum.datum_content_id,
            title: datum.title,
            description: datum.description,
            links: links,
            uploads: uploads,
        });

        generateSpecificCnARoute(datum_id)
        setShowCreateItemModal(true);
        setSelectedEmailMembers([]);
    }

    const generateSpecificCnARoute = async (datum_id: number) => {
        const organization_id = team_obj.data.idOrganization;
        const team_id = team_obj.data.id;
        let route = `/organizations/${organization_id}/teams/${team_id}/CnA/${datum_id}`
        dispatch(setRoute(route))
        dispatch( setDatumIdOpen(datum_id) );
        let selected_datum = findDatumByDatumIdInAllTools(cna_data, parseInt(cna.datum_id_open))
        if(selected_datum !== undefined) {
            await dispatch( setDatumIdOpenData(0) );
            await dispatch( setDatumIdOpenData(selected_datum) );
        }
    }

    const clickAddComment = (datum_id:number, parent_comment:any = null) => {
        generateSpecificCnARoute(datum_id)
        let datum = findDatumByDatumId(cna_member_data[selectedDatumType.toLowerCase()].data, datum_id)
        setHeadlineOpenId(datum_id)
        setHeadlineCommentOpenId(parent_comment)

        let title = 'New Comment'
        if(parent_comment != null) {
            title = 'Add Reply'
        }

        setHeadlineModalSettings({
            ...headlineModalSettings,
            title: title,
            new_record: true,
            is_comment: true,
            labels: {
                title: 'Title',
                description: 'Comment'
            }
        });

        setHeadlineData({
            content_id: datum.datum_content_id,
            title: '',
            description: '',
            links: [],
            uploads: [],
        });
        setShowCreateItemModal(true);
        setSelectedEmailMembers([]);
    }

    const clickEditComment = (datum_id:any, comment_id:number, reply_id:number = null, reply2_id:number = null) => {
        generateSpecificCnARoute(datum_id)
        setHeadlineCommentOpenId(comment_id)
        setHeadlineOpenId(datum_id)
        let datum = findDatumByDatumId(cna_member_data[selectedDatumType.toLowerCase()].data, datum_id)
        let comment = datum.comments.find((comment: { id: any; }) => comment.id === comment_id);

        if(reply_id !== null) {
            comment = comment.comments.find((comment: { id: any; }) => comment.id === reply_id);
            setHeadlineCommentOpenId(reply_id)
        }

        if(reply2_id !== null) {
            comment = comment.comments.find((comment: { id: any; }) => comment.id === reply2_id);
            setHeadlineCommentOpenId(reply2_id)
        }

        setHeadlineModalSettings({
            ...headlineModalSettings,
            title: 'Edit Comment',
            new_record: false,
            is_comment: true
        });

        let links = comment.links.map((upload: { id: number, fileName: string, displayName: string; }) => {
            return {
                id: upload.document_teams.id,
                link: upload.document_teams.document.displayName,
            };
        });

        let uploads = comment.uploads.map((upload: { id: number, fileName: string, displayName: string; }) => {
            return {
                id: upload.document_teams.id,
                file_name: upload.document_teams.document.path,
                display_name: upload.document_teams.document.displayName,
            };
        });

        setHeadlineData({
            content_id: datum.datum_content_id,
            title: '',
            description: comment.comment,
            links: links,
            uploads: uploads,
        });

        setShowCreateItemModal(true);
        setSelectedEmailMembers([]);
    }

    const clickRemoveComment = (datum_id:any, comment_id:number) => {
        const organization_id = team_obj.data.idOrganization;
        const team_id = team_obj.data.id;

        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        url += `/tools/CnA/${datum_id}/comments/${comment_id}`;

        Swal.fire({
            title: `Are you sure you want to delete this comment?`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Comment Deleted!', '', 'success')
                axios.delete(`${url}`)
                    .then((response) => {
                        dispatch( getCnAData({organization_id, team_id}) );
                    }).catch((error) => {
                    throw error;
                })
            }
        })
    }

    const clickDeleteItem = async (datum_id:number) => {
        const organization_id = team_obj.data.idOrganization;
        const team_id = team_obj.data.id;
        let datum = findDatumByDatumId(cna_member_data[selectedDatumType.toLowerCase()].data, datum_id)

        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        url += `/tools/CnA/${datum_id}?content_id=${datum.datum_content_id}&type=${datum.type1}`;

        Swal.fire({
            title: `Are you sure you want to delete this item?`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Item Deleted!', '', 'success')
                axios.delete(`${url}`)
                    .then((response) => {
                        dispatch( getCnAData({organization_id, team_id}) );
                    }).catch((error) => {
                        throw error;
                    })
            }
        })
    }

    const clickPrivateItem = async (datum_id:number) => {
        let datum = findDatumByDatumId(cna_member_data[selectedDatumType.toLowerCase()].data, datum_id)
        const organization_id = team_obj.data.idOrganization;
        const team_id = team_obj.data.id;
        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        url += `/tools/CnA/${datum_id}/private?content_id=${datum.datum_content_id}`;

        await axios.post(`${url}`)
            .then((response) => {
                dispatch( getCnAData({organization_id, team_id}) );
                generateSpecificCnARoute(datum_id)
            }).catch((error) => {
                throw error;
            })
    }

    const clickMoveItem = (datum_id:number) => {
        setMoveItemSelectedItemId(datum_id)
        setMoveItemNewType('')
        setShowMoveItemModal(true)
    }

    const moveItemHandled = async () => {
        let datum_id = moveItemSelectedItemId;
        let type = moveItemNewType;
        let datum = findDatumByDatumId(cna_member_data[selectedDatumType.toLowerCase()].data, datum_id)
        const organization_id = team_obj.data.idOrganization;
        const team_id = team_obj.data.id;
        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        url += `/tools/CnA/${datum_id}/move?content_id=${datum.datum_content_id}&new_type=${type}`;

        await axios.post(`${url}`)
            .then((response) => {
                dispatch( getCnAData({organization_id, team_id}) );
                generateSpecificCnARoute(datum_id)
            }).catch((error) => {
                throw error;
            })
    }

    const clickArchiveItem = async (datum_id:number) => {
        let datum = findDatumByDatumId(cna_member_data[selectedDatumType.toLowerCase()].data, datum_id)
        await handleActiveArchiveItem(datum_id, datum.datum_content_id)
    }

    const clickActiveItem = async (datum_id:number) => {
        let datum = findDatumByDatumId(cna_member_archived_data[selectedDatumType.toLowerCase()].data, datum_id)
        await handleActiveArchiveItem(datum_id, datum.datum_content_id)
    }

    const handleActiveArchiveItem = async (datum_id:any, content_id:any) => {
        const organization_id = team_obj.data.idOrganization;
        const team_id = team_obj.data.id;
        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        url += `/tools/CnA/${datum_id}/archive?content_id=${content_id}`;

        await axios.post(`${url}`)
            .then((response) => {
                dispatch( getCnAData({organization_id, team_id}) );
                generateSpecificCnARoute(datum_id)
            }).catch((error) => {
                throw error;
            })
    }

    const handleChangeListOrder = async (item_id: number, newPriority: number, parentDatumId:number = null) => {
        generateSpecificCnARoute(item_id)
        const organization_id = team_obj.data.idOrganization;
        const team_id = team_obj.data.id;

        let datum = findDatumByDatumId(cna_member_data[selectedDatumType.toLowerCase()].data, item_id)

        let selected_datum_items = cna_member_data[selectedDatumType.toLowerCase()].data;
        if(datum.idParent !== null) {
            let parent_datum = findDatumByDatumId(cna_member_data[selectedDatumType.toLowerCase()].data, datum.idParent)
            selected_datum_items = parent_datum.sub_data;
        }

        let datum_content = changePriority(selected_datum_items, item_id, newPriority).map((datum:any) => {
            return datum.datum_content_id
        });

        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        url += `/tools/CnA/change_list_order`;
        await axios.put(url, {datum_content_id: datum_content})
            .then((response) => {
                dispatch( getCnAData({organization_id, team_id}) );
            }).catch((error) => {
                throw error;
            })
    }

    const handleMarKNotificationsAsRead = async (item_id: number, type: 'newChange') => {
        generateSpecificCnARoute(item_id)
        const organization_id = team_obj.data.idOrganization;
        const team_id = team_obj.data.id;

        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        url += `/tools/CnA/${item_id}/mark_notifications_as_read`;
        await axios.post(url, {type: type})
            .then((response) => {
                dispatch( getCnAData({organization_id, team_id}) );
            }).catch((error) => {
                throw error;
            })
    }

    const handleOpenReminder = (item_id:number) => {
        let datum = findDatumByDatumId(cna_member_data[selectedDatumType.toLowerCase()].data, item_id)
        if(datum.datumNotificationReminderId != null && datum.datumNotificationReminderId > 0 &&
            datum.datumNotificationReminderComments.trim() != '')
        {
            setIsNewReminder(false)
            setReminder(datum.datumNotificationReminderComments)
        } else {
            setIsNewReminder(true)
            setReminder('')
        }
        setShowReminderModal(true)
        setHeadlineOpenId(item_id)
    }

    const handleSubmitReminder = async (type: any) => {
        const organization_id = team_obj.data.idOrganization;
        const team_id = team_obj.data.id;
        
        let datum = findDatumByDatumId(cna_member_data[selectedDatumType.toLowerCase()].data, headlineOpenId)
        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        url += `/tools/CnA/${headlineOpenId}/reminders`;

        if(type == 'delete' || !isNewReminder) {
            url = `${url}/${datum.datumNotificationReminderId}`
            let data = {
                content_id: datum.datum_content_id,
                comments: reminder,
                status: "unread"
            }

            if(type == 'delete') {
                data.status = "read"
            } else {
                if(reminder.trim() == '') {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Please add your reminder'
                    })
                }
            }

            await axios.put(`${url}`, data)
                .then((response) => {
                    dispatch( getCnAData({organization_id, team_id}) );
                    setReminder('')
                    setShowReminderModal(false)
                }).catch((error) => {
                    throw error;
                })
            //Update/Delete
        } else {
            if(reminder.trim() == '') {
                Swal.fire({
                    icon: 'warning',
                    text: 'Please add your reminder'
                })
            }

            //Insert
            let data = {
                content_id: datum.datum_content_id,
                comments: reminder
            }
            await axios.post(`${url}`, data)
                .then((response) => {
                    dispatch( getCnAData({organization_id, team_id}) );
                    setReminder('')
                    setShowReminderModal(false)
                }).catch((error) => {
                    throw error;
                })
        }
    }

    const clickOpenTabHandled = (tab: string) => {
        const organization_id = team_obj.data.idOrganization;
        const team_id = team_obj.data.id;

        setSelectedDatumType(tab)

        let rememberData:any = {
            organization_id: organization_id,
            team_id: team_id,
            view: 'member',
            user_id: selectedMember,
            tab_name: tab.toLowerCase(),
            selected_item_id: null,
        }

        dispatch(collabSetRemember(rememberData))
    }

    const handleSelectItem = (datum:any) => {
        const organization_id = team_obj.data.idOrganization;
        const team_id = team_obj.data.id;

        let rememberData:any = {
            organization_id: organization_id,
            team_id: team_id,
            view: 'member',
            user_id: selectedMember,
            tab_name: selectedDatumType.toLowerCase(),
            selected_item_id: datum.id,
        }

        dispatch(collabSetRemember(rememberData))
    }

    return (
        <div className="container cna-member-panel-container">
            <div className="col-12 offset-0 col-xl-6 offset-xl-3">
                <Nav variant="tabs" style={{fontSize: '13px'}}>
                    {
                        datum_types.map(type => (
                            <Nav.Item
                                className={'border mx-0 mb-0'}
                                onClick={() => {clickOpenTabHandled(type.name)}}
                                style={{marginRight: '5px'}}>

                                <Nav.Link className={type.name == selectedDatumType ? 'active' : ''}>
                                    {type.name == 'ToMIs' ? 'ToMIs™' : type.name}

                                    {
                                        cna_member_data[type.name.toLowerCase()].total_notifications > 0 &&
                                        <sup style={{color: "red", fontWeight: 'bold', fontSize: '12px'}}>{cna_member_data[type.name.toLowerCase()].total_notifications}</sup>
                                    }
                                    {
                                        (
                                            (!selected_member_is_owner && type.name == 'Messages') //Page from other members but tab is message
                                            ||
                                            (selected_member_is_owner && type.name != 'Messages')) //Page from logged member for all tabs, except: Message
                                        &&
                                        <i title='Add New Item' className='mx-1 color-teal clickable badge bg-teal p-1'
                                           style={{height: 'fit-content'}}
                                           onClick={() => clickNewItem(type.name)}><FontAwesomeIcon icon={faPlus}/></i>
                                    }
                                </Nav.Link>
                            </Nav.Item>
                        ))
                    }


                    <HeadlineModal
                        showCreateModal={showCreateItemModal}
                        setShowCreateModal={setShowCreateItemModal}
                        handleCloseModal={handleCloseCreateCoachNoteModal}
                        data={headlineData}
                        setData={setHeadlineData}
                        submitHandled={headlineSubmit}
                        settings={headlineModalSettings}
                        showEmailMembers={true}
                        emailMemberList={team_obj.data.members}
                        selectedEmailMembers={selectedEmailMembers}
                        setSelectedEmailMembers={setSelectedEmailMembers}
                        disableSubmitBtn={disableSubmitBtn}
                    />
                    <ReminderModal
                        showCreateModal={showReminderModal}
                        handleCloseModal={handleCloseReminderModal}
                        isNewReminder={isNewReminder}
                        reminder={reminder}
                        setReminder={setReminder}
                        submitHandled={handleSubmitReminder}
                        disableSubmitBtn={disableSubmitBtn}
                    />

                    <Modal show={showMoveItemModal} onHide={() => setShowMoveItemModal(false)} dialogAs={DraggableModalDialog} style={{cursor: 'grab'}}>
                        <Form>
                            <Modal.Header closeButton className='bg-teal draggableHandler'>
                                <Modal.Title>Move Item to:</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='row d-flex align-items-center'>
                                <div className="col-12">
                                    <select className='form-select' value={moveItemNewType} onChange={(event) => setMoveItemNewType(event.target.value)}>
                                        <option value="">Select an option</option>
                                        <option value="Deliverable">Deliverables</option>
                                        <option value="Headline">ToMIs™</option>
                                        <option value="Activity">Activities</option>
                                    </select>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowMoveItemModal(false)}>
                                    Cancel
                                </Button>
                                <Button className='btn-teal' onClick={() => {moveItemHandled()}} disabled={disableSubmitBtn}>
                                    Move
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                </Nav>
            </div>

            <div className="col-12 offset-0 col-xl-6 offset-xl-3 mt-2">
                {
                    <Accordion defaultActiveKey={datumModalOpenKey} alwaysOpen>
                        {
                            cna_member_data[selectedDatumType.toLowerCase()].data.map((datum:any) => {

                                if(selected_member_is_owner === false && datum.isPrivate == 'Private' ) {
                                    return <></>
                                }

                                return <DatumAccordionItem eventKey={datum.id.toString()}
                                                    key={datum.id}
                                                    datum={datum}
                                                    total_coach_notes={cna_member_data[selectedDatumType.toLowerCase()].data.length}
                                                    handleEditItem={clickEditItem}
                                                    handleAddComment={clickAddComment}
                                                    handleEditComment={clickEditComment}
                                                    clickRemoveComment={clickRemoveComment}
                                                    handleDeleteItem={clickDeleteItem}
                                                    handleArchiveItem={clickArchiveItem}
                                                    handleMarkPrivateItem={clickPrivateItem}
                                                    clickMoveItem={clickMoveItem}
                                                    handleChangeListOrder={handleChangeListOrder}
                                                    handleOpenReminder={handleOpenReminder}
                                                    handleMarKNotificationsAsRead={handleMarKNotificationsAsRead}
                                                    isSubItem={false}
                                                    clickNewItem={clickNewItem}
                                                    readOnly={!selected_member_is_owner}
                                                    selectedMember={selectedMember}
                                                    subdatumModalOpenKey={subdatumModalOpenKey}
                                                    handleSelectItem={handleSelectItem}
                                />
                            })
                        }
                    </Accordion>
                }
            </div>

            <div className="col-12 offset-0 col-xl-6 offset-xl-3 mt-2">
                <h5 className='text-muted'>Archived {selectedDatumType}</h5>
                {
                    isLoadingArchivedData ?
                        <span className='text-muted'>Loading...</span>
                        :
                        (
                            (cna_member_archived_data != undefined && cna_member_archived_data.hasOwnProperty(selectedDatumType.toLowerCase()) && cna_member_archived_data[selectedDatumType.toLowerCase()].data.length > 0)
                                ?
                                <Accordion defaultActiveKey={datumModalOpenKey} alwaysOpen>
                                    {
                                        cna_member_archived_data[selectedDatumType.toLowerCase()].data.map((datum:any) => (
                                            <DatumAccordionItem eventKey={datum.id.toString()}
                                                                key={datum.id}
                                                                datum={datum}
                                                                total_coach_notes={cna_member_archived_data[selectedDatumType.toLowerCase()].data.length}
                                                                handleActiveItem={clickActiveItem}
                                                                isSubItem={false}
                                                                readOnly={!selected_member_is_owner}
                                                                selectedMember={selectedMember}
                                                                subdatumModalOpenKey={subdatumModalOpenKey}
                                            />
                                        ))
                                    }
                                </Accordion>
                                :
                                <span className='text-muted'>No Archived {selectedDatumType}</span>
                        )
                }
            </div>
        </div>
    )
}
